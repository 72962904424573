import {
    render,
    MARK_BOLD,
    MARK_LINK,
    NODE_HEADING,
    NODE_PARAGRAPH,
    NODE_OL,
    NODE_UL,
    StoryblokRichtext,
    MARK_ANCHOR,
} from "storyblok-rich-text-react-renderer";
import { CardComponent as CardModel } from "movestic-blocks/components/__generated__/graphql";
import { CardlistComponent as CardListModel } from "movestic-blocks/components/__generated__/graphql";
import { TextblocklistComponent as TextBlockListModel } from "movestic-blocks/components/__generated__/graphql";
import { ButtonComponent as ButtonModel } from "movestic-blocks/components/__generated__/graphql";
import { TextblockComponent as TextBlockModel } from "movestic-blocks/components/__generated__/graphql";
import { ShoutoutComponent as ShoutoutModel } from "movestic-blocks/components/__generated__/graphql";
import { FaqlistComponent as FaqListModel } from "movestic-blocks/components/__generated__/graphql";
import { YoutubeComponent as YoutubeModel } from "movestic-blocks/components/__generated__/graphql";
import { GlobalblockComponent as GlobalBlockModel } from "movestic-blocks/components/__generated__/graphql";
import { AccordionlistComponent as AccordionListModel } from "movestic-blocks/components/__generated__/graphql";
import { ContactlistComponent as ContactListModel } from "movestic-blocks/components/__generated__/graphql";
import { TableComponent as TableModel } from "movestic-blocks/components/__generated__/graphql";
import { QuoteComponent as QuoteModel } from "movestic-blocks/components/__generated__/graphql";
import { ImageComponent as ImageModel } from "movestic-blocks/components/__generated__/graphql";
import { ModuleComponent as ModuleModel } from "movestic-blocks/components/__generated__/graphql";
import { StepComponent as StepModel } from "movestic-blocks/components/__generated__/graphql";
import { ExpandablebuttonComponent as ExpandableButtonModel } from "movestic-blocks/components/__generated__/graphql";
import { NewslistComponent as LatestNewsModel } from "movestic-blocks/components/__generated__/graphql";

import { CardBlock, CardBlockList } from "./blocks/CardBlock";
import { getLink } from "movestic-blocks/utils/cms/storyblokHelpers";
import {
    PrimaryLinkButton,
    SecondaryLinkButton,
    PrimaryLink,
    BaseLinkButton,
    LinkProps,
} from "movestic-core/tailwind/Buttons";
import { TextBlock, TextBlockList } from "./blocks/TextBlock";
import { EmbedMap } from "./blocks/EmbedMap";
import { Shoutout } from "./blocks/Shoutout";
import { Quote } from "./blocks/Quote";
import { FaqList } from "./blocks/FaqList";
import { ShortcutList } from "./blocks/ShortcutList";
import { Youtube } from "./blocks/Youtube";
import { GlobalBlock } from "./blocks/GlobalBlock";
import { AccordionList } from "./blocks/AccordionList";
import { ContactList } from "./blocks/ContactList";
import { BasicTable } from "./blocks/Table";
import { ImageBlock } from "./blocks/ImageBlock";
import { H1, H2, H3, H4, H5 } from "movestic-core/tailwind/Texts";
import Link from "next/link";
import { ArrowRight } from "movestic-core/tailwind/icons/ArrowRight";
import { NewPage } from "movestic-core/tailwind/icons/NewPage";
import { Module } from "./blocks/Module";
import { StepBlock } from "./blocks/StepBlock";
import { ExpandableButton } from "./blocks/ExpandableButton";
import { LatestNewsBlock } from "./blocks/LatestNewsList";
import { ListBlock, OrderedListBlock } from "./blocks/ListBlock";

export const partialBlocks: any = {
    blokResolvers: {
        table: (props: TableModel) => <BasicTable {...props} />,
        card: (props: CardModel) => <CardBlock {...props} />,
        cardList: (props: CardListModel) => <CardBlockList {...props} />,
        textBlockList: (props: TextBlockListModel) => <TextBlockList {...props} />,
        shortcutList: (props: any) => <ShortcutList {...props} />,
        button: (props: ButtonModel) => <RichtextLinkButton {...props} />,
        textBlock: (props: TextBlockModel) => <TextBlock {...props} />,
        shoutout: (props: ShoutoutModel) => <Shoutout {...props} />,
        faqList: (props: FaqListModel) => <FaqList {...props} />,
        accordionList: (props: AccordionListModel) => <AccordionList {...props} />,
        contactList: (props: ContactListModel) => <ContactList {...props} />,
        embedMap: () => <EmbedMap />,
        youTube: (props: YoutubeModel) => <Youtube {...props} />,
        globalBlock: (props: GlobalBlockModel) => <GlobalBlock {...props} />,
        quote: (props: QuoteModel) => <Quote {...props} />,
        image: (props: ImageModel) => <ImageBlock {...props} />,
        module: (props: ModuleModel) => <Module {...props} />,
        step: (props: StepModel) => <StepBlock {...props} />,
        expandableButton: (props: ExpandableButtonModel) => <ExpandableButton {...props} />,
        newsList: (props: LatestNewsModel) => <LatestNewsBlock {...props}>NewsList</LatestNewsBlock>,
        list: (props: any) => <ListBlock {...props} />,
    },
    markResolvers: {
        [MARK_BOLD]: (children) => <strong>{children}</strong>,
        [MARK_LINK]: (children, props) => {
            const link = getLink(props);
            return (
                <Link href={link.linkUrl} target={link.linkTarget} className="underline hover:text-cherryRed">
                    {children}
                </Link>
            );
        },
        [MARK_ANCHOR]: (children, props) => {
            //Adjusting space to allow for sticky navbar
            return (
                <>
                    <span className="invisible absolute -mt-[4.5rem] pt-[4.5rem]" id={props.id}></span>
                    <span>{children}</span>
                </>
            );
        },
    },
    nodeResolvers: {
        [NODE_UL]: (children) => (
            <ListBlock
                listItems={children.map((x) => {
                    return { text: x?.props?.children };
                })}
            />
        ),
        [NODE_OL]: (children, props) => <OrderedListBlock {...props}>{children}</OrderedListBlock>,
        [NODE_PARAGRAPH]: (children) => <p>{children}</p>, // Needed to keep empty paragraphs
        [NODE_HEADING]: (children, props) => {
            switch (props.level) {
                case 1:
                    return <H1>{children}</H1>;
                case 2:
                    return <H2>{children}</H2>;
                case 3:
                    return <H3>{children}</H3>;
                case 4:
                    return <H4>{children}</H4>;
                case 5:
                    return <H5>{children}</H5>;
                default:
                    return <h6>{children}</h6>;
            }
        },
    },
};

export const articleHeroBlocks = {
    ...partialBlocks,
    nodeResolvers: {
        ...partialBlocks.nodeResolvers,
        [NODE_UL]: (children, props) => (
            <ul
                className="list-none [&>li]:pl-8 [&>li]:pb-2 [&>li]:bg-[url('/static/images/icon-check.svg')] [&>li]:bg-no-repeat [&>li_p]:inline"
                {...props}
            >
                {children}
            </ul>
        ),
    },
};

export const noGapSectionImage = {
    ...partialBlocks,
    blokResolvers: {
        ...partialBlocks.blokResolvers,
        image: (props) => <ImageBlock {...props} noBorderRadius={true} />,
    },
};

export const cherryDarkContent = {
    ...partialBlocks,
    blokResolvers: {
        ...partialBlocks.blokResolvers,
        button: (props) => <RichtextLinkButton {...props} whiteColor={true} />,
        textBlock: (props) => <TextBlock {...props} cherryDarkBackground={true} />,
    },
    markResolvers: {
        ...partialBlocks.markResolvers,
        [MARK_LINK]: (children, props) => {
            const link = getLink(props);
            return (
                <Link
                    href={link.linkUrl}
                    target={link.linkTarget}
                    className="text-white underline hover:text-lightGray"
                >
                    {children}
                </Link>
            );
        },
    },
};

export const footerContent = {
    ...partialBlocks,
    blokResolvers: {
        ...partialBlocks.blokResolvers,
        button: (props) => <RichtextLinkButton {...props} whiteColor={true} />,
    },
    markResolvers: {
        ...partialBlocks.markResolvers,
        [MARK_LINK]: (children, props) => {
            const link = getLink(props);
            return (
                <Link
                    href={link.linkUrl}
                    target={link.linkTarget}
                    className="leading-8 text-white no-underline md:leading-4 hover:text-lightGray"
                >
                    {children}
                </Link>
            );
        },
    },
};

// export const footerContent = {
//     ...partialBlocks,
//     blokResolvers: {
//         ...partialBlocks.blokResolvers,
//         button: (props) => <RichtextLinkButton {...props} whiteColor={true} />,
//     },
//     markResolvers: {
//         ...partialBlocks.markResolvers,
//         [MARK_LINK]: (children, props) => {
//             const link = getLink(props);
//             return (
//                 <Link
//                     href={link.linkUrl}
//                     target={link.linkTarget}
//                     className="text-white no-underline hover:text-lightGray"
//                 >
//                     {children}
//                 </Link>
//             );
//         },
//     },
// };

export const strongLinkBlocks = {
    ...partialBlocks,
    markResolvers: {
        ...partialBlocks.markResolvers,
        [MARK_LINK]: (children, props) => {
            const link = getLink(props);
            return (
                <Link href={link.linkUrl} target={link.linkTarget} className="font-bold no-underline text-cherryDark">
                    {children}
                </Link>
            );
        },
    },
};

export function RichtextContent({
    richContent,
    resolvers,
    className,
}: {
    richContent: StoryblokRichtext;
    resolvers?: any;
    className?: string;
}) {
    if (!richContent) {
        return null;
    }

    return (
        <div
            className={`[&>*]:mb-2 [&>div]:mb-6 [&_h1]:mb-6 [&_h2]:mb-6 [&_h3]:mb-6 [&>h1]:mt-6 [&>h2]:mt-6 [&>h3]:mt-6 [&>:last-child]:mb-0 [&>:first-child]:mt-0 [&>p:empty]:pt-2 [img]:align-bottom ${className ?? ""}`}
        >
            {render(richContent, resolvers || partialBlocks)}
        </div>
    );
}

interface ExtendedButtonModel extends ButtonModel {
    whiteColor?: boolean;
}

const PrimaryLinkButtonWhite = ({ children, className, ...props }: LinkProps) => (
    <BaseLinkButton
        className={`font-bold bg-white font-neutraface hover:bg-cherryBloom text-cherryDark ${className}`}
        {...props}
    >
        {children}
    </BaseLinkButton>
);

const SecondaryLinkButtonWhite = ({ children, className, ...props }: LinkProps) => (
    <BaseLinkButton
        className={`font-bold bg-cherryDark border-white border text-white font-neutraface hover:bg-white hover:text-cherryDark disabled:(bg-white border-cherryShadow text-cherryShadow opacity-50) ${className}`}
        {...props}
    >
        {children}
    </BaseLinkButton>
);

const PrimaryLinkWhite = ({ children, className, href, ...props }: LinkProps) => (
    <Link className={`font-bold text-white no-underline hover:text-lightGray ${className}`} href={href} {...props}>
        {children}
    </Link>
);

function RichtextLinkButton({ text, link, type, whiteColor = false }: ExtendedButtonModel) {
    const lnk = getLink(link);

    let Button = PrimaryLinkButton;

    switch (type) {
        case "primary":
            Button = whiteColor ? PrimaryLinkButtonWhite : PrimaryLinkButton;
            break;
        case "secondary":
            Button = whiteColor ? SecondaryLinkButtonWhite : SecondaryLinkButton;
            break;
        case "linkArrow":
        case "linkNewPage":
            Button = whiteColor ? PrimaryLinkWhite : PrimaryLink;
            break;
        default:
            Button = PrimaryLinkButton;
    }

    return (
        <div className="flex">
            <Button href={lnk.linkUrl} target={lnk.linkTarget}>
                <>
                    {text}
                    {type === "linkArrow" ? (
                        <ArrowRight className="inline mb-1 ml-2" />
                    ) : type === "linkNewPage" ? (
                        <NewPage className="inline mb-1 ml-2" />
                    ) : null}
                </>
            </Button>
        </div>
    );
}
