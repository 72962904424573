"use client";
import { ExpandablebuttonComponent as ExpandableButtonModel } from "movestic-blocks/components/__generated__/graphql";
import { RichtextContent } from "movestic-blocks/tailwind/RichtextBlockRenderer";
import { Chevron } from "movestic-core/tailwind/icons/Chevron";
import { useState } from "react";

export const ExpandableButton = (props: ExpandableButtonModel) => {
    const [isOpen, setOpen] = useState(false);
    return (
        <div className="">
            <button className={`flex font-bold text-cherryDark`} tabIndex={0} onClick={() => setOpen(!isOpen)}>
                <div className="font-bold text-left">{props.title}</div>
                <div className={`ml-2 transition ${isOpen ? "rotate-180" : ""}`}>
                    <Chevron />
                </div>
            </button>
            <div
                className={`text-left overflow-hidden transition-opacity transition-max-height ${isOpen ? "opacity-100 max-h-[3500px]" : "opacity-0 max-h-0"}`}
            >
                <RichtextContent richContent={props.contentArea} />
            </div>
        </div>
    );
};
