"use client";
import {
    FaqlistComponent as FaqlistModel,
    FaqitemComponent as FaqitemModel,
} from "movestic-blocks/components/__generated__/graphql";
import { Chevron } from "movestic-core/tailwind/icons/Chevron";
import { H3 } from "movestic-core/tailwind/Texts";
import { useState } from "react";
import { RichtextContent } from "movestic-blocks/tailwind/RichtextBlockRenderer";

const FaqItem = ({ question, answer2 }: FaqitemModel) => {
    const [open, setOpen] = useState(false);
    return (
        <div className={`flex flex-col border-t border-lightGray `}>
            <button className="flex justify-between py-4 cursor-pointer" tabIndex={0} onClick={() => setOpen(!open)}>
                <div className="font-bold text-left">{question}</div>
                <div className={`transition ${open ? "rotate-180" : ""}`}>
                    <Chevron />
                </div>
            </button>
            <div
                className={`text-left transition ${open ? "visible opacity-100 max-h-100" : "invisible opacity-0 max-h-0"}`}
            >
                <div className="pb-4">
                    <RichtextContent richContent={answer2} />
                </div>
            </div>
        </div>
    );
};

export const FaqList = (props: FaqlistModel) => {
    const faqItems = props.faqItems as FaqitemModel[];
    const faqTags = [...new Set<string>(props.faqItems.flatMap((p) => p.tag)?.filter((x) => x))];
    const [tag, setTag] = useState<string>(null);
    const activeClassnames = "font-bold border-2 border-cherryRed bg-cherryMist";
    const tagClassnames =
        "text-lightBlack border rounded-lg p-2 px-4 whitespace-nowrap cursor-pointer hover:bg-cherryMist";

    return (
        <>
            {props.heading && <H3>{props.heading}</H3>}
            {props.showTags && (
                <div className="flex flex-wrap justify-center gap-4">
                    <div
                        className={`${tagClassnames} ${tag === null ? activeClassnames : "border-lightGray bg-white"}`}
                        onClick={() => setTag(null)}
                    >
                        Alla
                    </div>
                    {faqTags.map((tagItem) => (
                        <div
                            key={tagItem}
                            className={`${tagClassnames} ${tagItem === tag ? activeClassnames : "border-lightGray bg-white"}`}
                            onClick={() => setTag(tagItem === tag ? null : tagItem)}
                        >
                            {tagItem}
                        </div>
                    ))}
                </div>
            )}
            <div className="w-full">
                {faqItems?.map((item, index) => (item.tag?.includes(tag) || !tag) && <FaqItem key={index} {...item} />)}
            </div>
        </>
    );
};
